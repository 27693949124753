
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TicketsCannedResponsesDropdown from '@/components/pages/tickets/settings/cannedResponses/TicketsCannedResponsesDropdown.vue'

export default defineComponent({
  components: {
    TicketsCannedResponsesDropdown,
    TmTable,
    TmBadge,
  },
})

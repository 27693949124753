import type { CannedResponsesItemType } from '@/definitions/tickets/settings/cannedResponses/types'

export const cannedResponsesTableItems: CannedResponsesItemType[] = [
  {
    id: 1,
    name: 'goodbye',
    response: 'Thank you for reaching out to us! If you have any more questions in the future, don\'t hesitate to contact our support team. We\'re ',
    visible: 'All users',
    lastUpdated: '12 Apr 2022, 11:30 am',
  },
  {
    id: 2,
    name: 'refund',
    response: 'Hello {{ First name }}, Thank you for your email. We are looking forward to resolved this. In case of more question - we are here to help you. Fames ipsum faucibus sit viverra consequat volutpat amet imperdiet ultricies. Interdum nullam at s... ',
    visible: 'All users',
    lastUpdated: '10 Sep 2021, 06:30 pm',
  },
  {
    id: 3,
    name: 'welcome',
    response: 'Hello and welcome! We\'re thrilled to have you on board. If you need any assistance or have any questions, feel free to reach out. Our tea',
    visible: 'All users',
    lastUpdated: '8 Jan 2021, 10:15 am',
  },
  {
    id: 4,
    name: 'thank you',
    response: 'We truly appreciate your feedback and are glad we could assist you! If there\'s anything more we can help with',
    visible: 'Only me',
    lastUpdated: '5 May 2023, 02:55 pm',
  },
  {
    id: 5,
    name: 'help',
    response: 'Here are the steps to get you started with [feature or tool name]. If you need further assistance, please don\'t hesit',
    visible: 'All users',
    lastUpdated: '5 Oct 2023, 02:25 pm',
  },
]

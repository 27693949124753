
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useModals } from '@/compositions/modals'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'

export default defineComponent({
  components: { TopFilterButton, TopFilter },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openModal } = useModals()
    const { openDeleteCannedResponses } = useTicketsOpenModals()

    return {
      openDeleteCannedResponses,
      openModal,
    }
  },
})

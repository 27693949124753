
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TicketsCannedResponsesFilter from '@/components/pages/tickets/settings/cannedResponses/TicketsCannedResponsesFilter.vue'
import TicketsCannedResponsesTable from '@/components/pages/tickets/settings/cannedResponses/TicketsCannedResponsesTable.vue'
import { cannedResponsesTableItems } from '@/definitions/tickets/settings/cannedResponses/data'
import TicketsCannedResponsesEmpty
  from '@/components/pages/tickets/settings/cannedResponses/TicketsCannedResponsesEmpty.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TicketsCannedResponsesEmpty,
    TicketsCannedResponsesTable,
    TicketsCannedResponsesFilter,
    PageContent,
    DetailsHero,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Canned responses', url: '' },
    ]
    const search = ref()
    const selected = ref()
    const tableItems = ref(cannedResponsesTableItems)
    const expand = ref(false)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name' },
      { text: 'Response', value: 'response', minWidth: '250px', expandPossible: true },
      { text: 'Visible to', value: 'visible' },
      { text: 'Last updated', value: 'lastUpdated' },
    ])

    return {
      expand,
      tableItems,
      tableHeaders,
      search,
      selected,
      breadcrumbs,
      isEmptyMode,
    }
  },
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center font-weight-medium emphasize--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tickets_canned_responses_dropdown = _resolveComponent("tickets-canned-responses-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "flash_on",
          class: "distinct--text mr-2"
        }),
        _createTextVNode(" #" + _toDisplayString(props.row.name), 1)
      ])
    ]),
    "body-cell-visible": _withCtx((props) => [
      _createVNode(_component_tm_badge, { static: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.visible), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_tickets_canned_responses_dropdown)
    ]),
    _: 1
  }))
}